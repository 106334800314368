import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { ContactForm } from "@/components/contact-form";
import { Timeline } from "@/components/timeline";
import { Arrow } from "@/components/arrow";
import { GatsbySeo, JsonLd } from "gatsby-plugin-next-seo";
import { Layout } from "@/components/layout";

const DevOpsSolutions = () => {
  return (
    <>
      <GatsbySeo
        title="Devops services in Malaysia | USA - Tectratechnologies"
        description="DevOps makes this possible by bringing business, development and operation teams together and applying automated processes to streamline IT."
        canonical="https://www.tectratechnologies.com/dev-ops-solutions/"
        openGraph={{
          locale: "en_US",
          type: "article",
          url: "https://www.tectratechnologies.com/dev-ops-solutions/",
          title:
            "Devops services in Malaysia | USA - Tectratechnologies",
          description:
            "DevOps makes this possible by bringing business, development and operation teams together and applying automated processes to streamline IT.",
          site_name: "Tectra Technologies",
        }}
        twitter={{
          handle: "@handle",
          site: "@TectraL",
          cardType: "summary_large_image",
        }}
      />
      <JsonLd
        json={{
          "@context": "https://schema.org",
          "@type": "WebSite",
          "@graph": [
            {
              "@type": "WebSite",
              "@id": "https://www.tectratechnologies.com/#website",
              url: "https://www.tectratechnologies.com/",
              name: "Tectra Technologies",
              description:
                "Web &amp; Mobile App, Cloud, UI/UX, Devops, Tax Services, Digital Marketing",
              potentialAction: [
                {
                  "@type": "SearchAction",
                  target: {
                    "@type": "EntryPoint",
                    urlTemplate:
                      "https://www.tectratechnologies.com/?s={search_term_string}",
                  },
                  "query-input": "required name=search_term_string",
                },
              ],
              inLanguage: "en-US",
            },
            {
              "@type": "WebPage",
              "@id": "https://www.tectratechnologies.com/dev-ops-solutions/",
              url: "https://www.tectratechnologies.com/dev-ops-solutions/",
              name: "Devops services in Malaysia | USA - Tectratechnologies",
              isPartOf: {
                "@id": "https://www.tectratechnologies.com/#website",
              },
              datePublished: "2019-08-21T06:13:58+00:00",
              dateModified: "2021-07-14T08:14:59+00:00",
              description:
                "DevOps makes this possible by bringing business, development and operation teams together and applying automated processes to streamline IT.",
              breadcrumb: {
                "@id":
                  "https://www.tectratechnologies.com/dev-ops-solutions/#breadcrumb",
              },
              inLanguage: "en-US",
              potentialAction: [
                {
                  "@type": "ReadAction",
                  target: [
                    "https://www.tectratechnologies.com/dev-ops-solutions/",
                  ],
                },
              ],
            },
            {
              "@type": "BreadcrumbList",
              "@id":
                "https://www.tectratechnologies.com/dev-ops-solutions/#breadcrumb",
              itemListElement: [
                {
                  "@type": "ListItem",
                  position: 1,
                  name: "Home",
                  item: "https://www.tectratechnologies.com/",
                },
                {
                  "@type": "ListItem",
                  position: 2,
                  name: "DevOps Solutions | DevOps Services",
                },
              ],
            },
          ],
        }}
      />
      <Layout>
        <div className="bg-white h-screen flex items-center justify-center">
          <div className="w-full max-w-7xl mx-auto px-5 lg:px-8">
            <div className="text-left xl:w-3/5">
              <div className="">
                <h1 className="text-black text-4xl font-poppins font-semibold mb-4 normal-case leading-relaxed">
                  Fueling IT industries to automate process while saving
                  resources.
                </h1>
                <div className="relative flex overflow-hidden justify-start items-center text-center">
                  <Link
                    to="/contact"
                    className="flex items-center text-xl normal-case hover:text-black"
                    activeClassName="border-b-2 border-black"
                  >
                    Talk to us
                    <Arrow color="black" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white mt-10 lg:mt-12">
          <div className="max-w-7xl mx-auto px-5 lg:px-8">
            <h2 className="text-black text-center text-2xl font-poppins font-semibold captalize">
              What makes our dev-ops solution services the best?
            </h2>
            <p className="max-w-5xl mx-auto text-center text-base text-black font-poppins font-thin leading-8 mt-4">
              We create successful DevOps via these pillars including
              developing, building, testing, Deploying, Operating, and
              monitoring. Although we create customized solutions for every
              brand the process remains the same. Here are the reasons to choose
              us for DevOps services
            </p>
            <div className="mt-8">
              <div className="w-full h-full">
                <StaticImage
                  src="../../images/ioskyuqwe787qwb.png"
                  alt=""
                  layout="constrained"
                  placeholder="blurred"
                  quality={95}
                  height={500}
                  className="w-full h-60 md:h-92 lg:h-auto"
                  formats={["auto", "png"]}
                />
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6 lg:gap-8 mt-8 lg:mt-12">
                <div className="text-black text-left">
                  <h3 className="text-xl font-poppins font-semibold captalize pl-2 border-l-4 border-[#9A9A9A] leading-none">
                    High-end Planning
                  </h3>
                  <p className="text-base text-black font-poppins font-thin leading-8 mt-4">
                    The first and foremost step is to create a roadmap via which
                    we can find the KPIs and hence work on them. The planning is
                    done with the assistance of advanced professionals who have
                    great knowledge and skills.
                  </p>
                </div>
                <div className="text-black text-left">
                  <h3 className="text-xl font-poppins font-semibold captalize pl-2 border-l-4 border-[#9A9A9A] leading-none">
                    Transparent &amp; Communicative
                  </h3>
                  <p className="text-base text-black font-poppins font-thin leading-8 mt-4">
                    Communication is the key to success. We have an open line
                    for communication to maintain transparency and project
                    visibility. Also, we organized weekly calls and meets with
                    our clients to update the status of our work timely.
                  </p>
                </div>
                <div className="md:col-span-2 text-black text-left">
                  <h3 className="text-xl font-poppins font-semibold captalize pl-2 border-l-4 border-[#9A9A9A] leading-none">
                    Confidentiality
                  </h3>
                  <p className="text-base text-black font-poppins font-thin leading-8 mt-4">
                    Maintaining the confidentiality of your work is our first
                    and foremost concern. Our developers are highly secure and
                    they keep all your information confidential to avoid data
                    leak problems. So you don't need to worry about your
                    information as it is in safe hands.
                  </p>
                </div>
              </div>
              <div className="mt-8 lg:mt-12">
                <h2 className="text-black text-center text-2xl font-poppins font-semibold captalize">
                  Why your business requires dev-ops?
                </h2>
                <div className="max-w-5xl mx-auto">
                  <p className="text-center text-base text-black font-poppins font-thin leading-8 mt-4">
                    In the ultra-competitive digital world, every business
                    requires DevOps as it's a software development approach that
                    helps in the faster development of new products for a
                    business. Without DevOps services, your business might face
                    these challenges:
                  </p>
                  <ul className="text-base font-poppins font-thin list-disc leading-8 ml-6">
                    <li className="mt-2">
                      Lack of communication and cultural balance between IT
                      operations team and software development team
                    </li>
                    <li className="mt-2">
                      Lack of faster application development
                    </li>
                    <li className="mt-2">
                      Negative impact on inner circle due to non-involvement of
                      DBAs in the release cycle
                    </li>
                    <li className="mt-2">
                      Lack of software-driven innovations
                    </li>
                  </ul>
                  <p className="text-base text-black  text-center font-poppins font-thin leading-8 mt-2">
                    All these issues can be together addressed by DevOps. So get
                    the best DevOps services from Tectra Technologies.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="relative overflow-hidden bg-white mt-10 lg:mt-12">
          <div className="mx-auto max-w-7xl px-0 lg:px-8 relative">
            <div className="grid grid-cols-1 items-center justify-center lg:gap-y-2 lg:grid-cols-12">
              <div className="flex flex-wrap items-center justify-center pb-4 lg:pr-12 sm:mx-0 sm:pb-0 lg:col-span-6 order-2 lg:order-1">
                <div className="relative z-10 gap-x-4 px-5 lg:px-0 lg:block lg:gap-x-0 lg:gap-y-1">
                  <p className="text-base text-black font-poppins font-thin leading-8 mb-0">
                    Our DevOps engineers have extensive experience in
                    collaborating development and operations using modern DevOps
                    tools and technologies like Jenkins, Bamboo, Jit, Gradle,
                    etc. Tectra DevOps team ensures that organizations can
                    successfully implement stable operating environments with
                    reduced development times and faster delivery times. By
                    stamping out all intricacies in the operational framework,
                    we establish automated end-to-end delivery collaboration,
                    monitoring, CI/CD Pipelines using secured platforms.
                  </p>
                  <p className="text-base text-black font-poppins font-thin leading-8 mt-4 mb-0">
                    Every organization needs a software infrastructure, either
                    local or cloud, that requires regular maintenance,
                    management, monitoring, improvement, and ongoing support.
                    The Tectra DevOps engineers are proficient in resolving
                    issues related to load reassignment, scale adjustment, and
                    resource optimization. We configure secure environments for
                    applications and software that enable them to work
                    seamlessly under all circumstances without any failures.
                  </p>
                </div>
              </div>
              <div className="lg:col-span-6 order-1 lg:order-2 mb-2 lg:mb-2">
                <div className="relative w-auto overflow-hidden lg:mt-0 lg:w-[67.8125rem]">
                  <div className="w-full h-full">
                    <StaticImage
                      src="../../images/DevOps1.png"
                      alt=""
                      layout="constrained"
                      placeholder="blurred"
                      quality={95}
                      height={350}
                      className="w-full"
                      formats={["auto", "png"]}
                    />
                  </div>
                  <div className="w-full h-full mt-4">
                    <StaticImage
                      src="../../images/DevOps21.png"
                      alt=""
                      layout="constrained"
                      placeholder="blurred"
                      quality={95}
                      height={350}
                      className="w-full"
                      formats={["auto", "png"]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <div className="bg-white mt-10 lg:mt-12">
          <div className="max-w-7xl mx-auto px-5 lg:px-8">
            <h2 className="text-black text-left text-2xl font-poppins font-semibold captalize">
              Process
            </h2>
            <div className="mt-6 ml-2 lg:ml-0">
              <Timeline
                items={[
                  "Technology Selection & Validation",
                  "Deployment Design",
                  "Deployment Automation",
                  "Configuration & Release Management",
                  "Performance Testing & UI Testing",
                ]}
              />
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default DevOpsSolutions;
